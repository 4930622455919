import { Component, inject, Output } from '@angular/core';
import { ContentBlock } from './content-block';
import { IN_EDITOR } from './content-editor/content-editor.component';
import { Subject } from 'rxjs';

@Component({ template: '' })
export class ContentBlockViewComponent<BlockT extends ContentBlock = ContentBlock> {
    inEditor = inject(IN_EDITOR, { optional: true }) ?? false;
    block: BlockT;
    @Output() viewLoaded = new Subject<void>();

    ngAfterViewInit() {
        this.onBlockChanged();
        this.viewLoaded.next();
    }

    private _knownBlock: string;

    ngDoCheck() {
        if (!this.inEditor)
            return;

        const blockJson = JSON.stringify(this.block);
        if (this._knownBlock !== blockJson) {
            this._knownBlock = blockJson;
            //alert('changed');
            setTimeout(() => this.onBlockChanged());
        }
    }

    onBlockChanged() {

    }
}
