import { Injectable, InjectionToken, Injector, Input, inject } from '@angular/core';
import { ContentBlockType } from './content-block-type';
import { ContentBlock } from './content-block';
import { ApiArticle, ApiPage } from '@tytapp/api';
import { deepEqualLoose, visitAllSubObjects } from '@tytapp/common';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Provides a way for content block implementations to be aware of the
 * current page. This is provided by the PageViewComponent. Consumer should
 * make sure to inject this optionally so that use of the block outside of the
 * page system (for instance within Articles) does not cause injection errors.
 */
export const CURRENT_PAGE_PROVIDER = new InjectionToken<{ readonly page: ApiPage }>('CURRENT_PAGE');
export const CURRENT_ARTICLE_PROVIDER = new InjectionToken<{ readonly article: ApiArticle }>('CURRENT_ARTICLE');

export const CONDITION_VALUE_TYPES = {
    boolean: { label: 'Boolean' },
    enum: { label: 'Enum' },
    timeOfDay: { label: 'Time Of Day' },
    dateTime: { label: 'Date/Time' },
    date: { label: 'Date' },
    number: { label: 'Number' },
    string: { label: 'String' }
} as const;

export const COMPARISON_OPERATORS = {
    equal: { label: '=' },
    'in': { label: 'One of' },
    prefix: { label: 'Starts with', onlyForTypes: ['string'] },
    suffix: { label: 'Ends with', onlyForTypes: ['string'] },
    contains: { label: 'Contains', onlyForTypes: ['string'] },
    regex: { label: 'Matches', onlyForTypes: ['string'] },

    lessThan: { label: '<', onlyForTypes: ['number'] },
    greaterThan: { label: '>', onlyForTypes: ['number'] },
    lessThanOrEqualTo: { label: '<=', onlyForTypes: ['number'] },
    greaterThanOrEqualTo: { label: '>=', onlyForTypes: ['number'] }
} as const;

export const LOGICAL_OPERATORS = {
    and: { label: 'Satisfy all' },
    or: { label: 'Satisfy any' }
} as const;

export type ConditionValueType = keyof typeof CONDITION_VALUE_TYPES;
export type ComparisonOperator = keyof typeof COMPARISON_OPERATORS;
export type LogicalOperator = keyof typeof LOGICAL_OPERATORS;

export interface ComparisonOperatorDefinition {
    label: string;
    disallowedForTypes?: readonly string[];
    onlyForTypes?: readonly string[];
}

export interface ConditionEnumOption {
    value: string;
    label: string;
}

export interface ConditionVariable {
    label: string;
    name: string;
    type: ConditionValueType;
    value?: (injector: Injector) => any;
    enumOptions?: ConditionEnumOption[];
};


export interface SimpleCondition {
    type: 'simple';
    variable: string;
    operator: ComparisonOperator;
    operand: any;
}

export interface ComplexCondition {
    type: 'complex';
    conditions: Condition[];
    operator: LogicalOperator;
}

export type Condition = SimpleCondition | ComplexCondition;

/**
 * Responsible for managing the available content block types, used in the Content Editor which is the core of both
 * Articles and Pages on TYT.com.
 */
@Injectable()
export class ContentBlocksService {
    private snackBar = inject (MatSnackBar);
    private injector = inject(Injector);
    private types: ContentBlockType[] = [];
    private conditionVariables: { [name: string]: ConditionVariable } = {};

    private conditionVariableOverrides: Record<string, any> = {};

    isConditionVariableOverridden(name: string) {
        return name in this.conditionVariableOverrides;
    }

    getOverriddenVariables() {
        return Object.keys(this.conditionVariableOverrides).map(x => this.getConditionVariable(x));
    }

    getConditionVariables() {
        return Object.values(this.conditionVariables);
    }

    static register(type: ContentBlockType) {
        inject(ContentBlocksService).register(type);
    }

    static registerConditionVariable(condition: ConditionVariable) {
        inject(ContentBlocksService).registerConditionVariable(condition);
    }

    registerConditionVariable(condition: ConditionVariable) {
        if (!this.conditionVariables[condition.name]) {
            this.conditionVariables[condition.name] = condition;
        }
    }

    async evaluateCondition(condition: Condition): Promise<boolean> {
        if (condition.type === 'simple')
            return await this.evaluateSimpleCondition(condition);
        else
            return await this.evaluateComplexCondition(condition);
    }

    async overrideConditionVariable(name: string, value: any) {
        this.conditionVariableOverrides[name] = value;
    }

    async removeConditionVariableOverride(name: string) {
        delete this.conditionVariableOverrides[name];
    }

    async getConditionVariableValue(name: string): Promise<any> {
        if (name in this.conditionVariableOverrides)
            return this.conditionVariableOverrides[name];

        let variable = this.getConditionVariable(name);
        if (!variable)
            return undefined;

        return await variable.value(this.injector);
    }

    private async evaluateSimpleCondition(condition: SimpleCondition, inEditor = false): Promise<boolean> {
        let variable = this.getConditionVariable(condition.variable);
        if (!variable)
            return false;

        let value = await this.getConditionVariableValue(variable.name);

        switch (condition.operator) {
            case 'equal':
                return deepEqualLoose(value, condition.operand);
            case 'in':
                if (!Array.isArray(condition.operand))
                    return false;
                return condition.operand.some(x => deepEqualLoose(value, x));
            case 'prefix':
                if (typeof value !== 'string' || typeof condition.operand !== 'string')
                    return false;
                return value.startsWith(condition.operand);
            case 'suffix':
                if (typeof value !== 'string' || typeof condition.operand !== 'string')
                    return false;
                return value.endsWith(condition.operand);
            case 'contains':
                if (typeof value !== 'string' || typeof condition.operand !== 'string')
                    return false;
                return value.includes(condition.operand);
            case 'regex':
                if (typeof value !== 'string' || typeof condition.operand !== 'string')
                    return false;
                try {
                    return new RegExp(condition.operand).test(value);
                } catch (e) {
                    console.error(`Invalid regular expression '${value}' encountered:`);
                    console.error(e);
                    return false;
                }
            case 'greaterThan':
                return value > condition.operand;
            case 'lessThan':
                return value < condition.operand;
            case 'greaterThanOrEqualTo':
                return value >= condition.operand;
            case 'lessThanOrEqualTo':
                return value <= condition.operand;

            default:
                condition.operator satisfies never;
        }
    }

    private async evaluateComplexCondition(condition: ComplexCondition): Promise<boolean> {
        if (condition.conditions.length === 0)
            return true;

        if (!['and', 'or'].includes(condition.operator))
            return false;

        if (condition.operator === 'and') {
            for (let part of condition.conditions) {
                let satisfied = await this.evaluateCondition(part);
                if (!satisfied)
                    return false;
            }

            return true;
        } else if (condition.operator === 'or') {
            for (let part of condition.conditions) {
                let satisfied = await this.evaluateCondition(part);
                if (satisfied)
                    return true;
            }

            return false;
        } else {
            console.error(`[ContentBlocksService/evaluateCondition] Unknown conditional type '${condition.operator}'`);
            return false;
        }
    }

    getConditionVariable(name: string): ConditionVariable {
        return this.conditionVariables[name];
    }

    register(type: ContentBlockType) {
        if (this.types.some(x => x.id === type.id))
            return;

        if (!type.deferredEditorComponent && !type.editorComponent)
            throw new Error(`The content block type '${type.id}' has no editor component specified`);
        if (!type.deferredViewComponent && !type.viewComponent)
            throw new Error(`The content block type '${type.id}' has no view component specified`);

        if (type.preview)
            type.preview['type'] = type.id;

        this.types.push(type);
    }

    visitContentBlocks(blocks: ContentBlock[], visitor: (block: ContentBlock) => boolean) {
        // This is a heuristic search-- we find anything that *resembles* a block and assumes it is.
        // It has to be this way unless we implement all container types (it is not enough to just look for a 'blocks'
        // field, consider the Tabs container block type)
        return visitAllSubObjects(blocks, (obj: any) => {
            if (obj.type && obj.id)
                return visitor(obj as ContentBlock);

            return true;
        });
    }

    /**
     * Load all deferred view components for blocks used in the given content. This prevents rendering empty boxes for
     * a few frames as the lazy chunks get loaded.
     * @param blocks
     */
    async preloadAllBlockViews(blocks: ContentBlock[]) {
        let types: Record<string, ContentBlockType> = {};
        this.visitContentBlocks(blocks, block => {
            let type = this.getById(block.type);
            if (type) {
                types[block.type] = type;
                type.earlyPreloadForViewing?.(this.injector, block);
            }

            return true;
        });

        await Promise.all(
            Object.keys(types)
                .map(type => this.getById(type)?.deferredViewComponent?.() ?? Promise.resolve())
        );
    }

    getPreview(type: ContentBlockType): ContentBlock {
        return type.preview as ContentBlock;
    }

    all() {
        return this.types.slice();
    }

    getFromBlock(block: ContentBlock) {
        return this.getById(this.getTypeFromBlock(block));
    }

    getTypeFromBlock(block: ContentBlock) {
        if (block.type)
            return block.type;

        let inferredType = this.inferTypeFromBlock(block);

        if (inferredType !== undefined) {
            block.type = inferredType;
        }

        return block.type
    }

    /**
     * Attempt to infer the type of a block from its contents, this may be needed for
     * old content in production.
     *
     * @param block
     * @returns
     */
    inferTypeFromBlock(block: ContentBlock & Record<string, any>) {
        if (block.image)
            return 'image';

        if (block.html_embed) {
            if (block.html_embed.startsWith('$offer:')) {
                return 'offer-set';
            } else if (block.html_embed.startsWith('$tweet:')) {
                return 'tweet';
            } else if (block.html_embed.startsWith('$youtube:')) {
                return 'youtube';
            }

            return 'embed';
        }

        if (block.body)
            return 'text';

        return undefined;
    }

    getById(id: string) {
        return this.types.find(x => x.id === id);
    }
}