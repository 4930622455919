import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { DialogComponent, NamedDialog } from '@tytapp/common';
import { ContentBlocksService } from '../content-blocks.service';
import { ContentBlockType } from '../content-block-type';
import { isClientSide } from '@tytapp/environment-utils';

@NamedDialog('content-block-picker')
@Component({
    templateUrl: './content-block-picker.component.html',
    styleUrls: ['./content-block-picker.component.scss']
})
export class ContentBlockPickerComponent extends DialogComponent {
    callback: (block: ContentBlockType) => void;

    init(callback: (block: ContentBlockType) => void) {
        if (!callback)
            alert(`No callback provided to block picker!`);

        this.callback = callback;

        if (isClientSide()) {
            document.addEventListener('keydown', this.keyHandler);
        }
    }

    destroy() {
        if (isClientSide()) {
            document.removeEventListener('keydown', this.keyHandler);
        }
    }

    private keyHandler = (ev: KeyboardEvent) => {
        console.log(ev.key);
        if (ev.key === 'ArrowLeft')
            this._selected = Math.max(0, this.selected - 1);
        else if (ev.key === 'ArrowRight')
            this._selected = Math.min(this.allBlockTypes.length - 1, this.selected + 1);
        else if (ev.key === 'Enter') {
            let type = this.allBlockTypes[this.selected];
            if (type)
                this.insert(type);
        }
    }

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    ngAfterViewInit() {
        if (isClientSide()) {
            setTimeout(() => {
                this.searchInput.nativeElement.focus();
            });
        }
    }

    query: string;
    blockTypes = inject(ContentBlocksService);
    _selected = 0;

    get selected() {
        return Math.max(0, Math.min(this.allBlockTypes.length - 1, this._selected));
    }

    get allBlockTypes() {
        return this.blockTypes.all().filter(x => JSON.stringify(x).toLowerCase().includes(this.query?.toLowerCase() ?? ''));
    }

    previewOf(blockType: ContentBlockType) {
        return this.blockTypes.getPreview(blockType);
    }

    insert(block: ContentBlockType) {
        this.callback(block);
        this.close();
    }
}